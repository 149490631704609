




































































































































import { Vue, Component, Prop , Watch} from 'vue-property-decorator'
@Component
export default class SearchToolBar extends Vue {
  @Prop({ required: true }) public serchInput!: string // 搜索框样式class
  @Prop({ required: true }) public eToolButton!: string // 按钮样式class
  @Prop({ required: true }) public buttonWidth!: string // 骨架按钮宽高

  @Prop({ required: true }) public searchWidth!: string // 调整搜索框长度
  @Prop() public searchMargin!: string // 调整搜索框间隔
  @Prop() public searchType!: string // 同页面多次使用时，获取返回值到指定调用方法
  @Prop() public tagHideFlag!: boolean // 是否显示list/tag切换显示(默认false或为空时页面显示该按钮功能)
  @Prop() public plusShowFlag!: boolean // 是否显示＋按钮(默认false或为空时页面显示该按钮功能)
  @Prop() public optionShowFlag!: boolean // 是否显示齿轮按钮（true显示）
  @Prop() public moreShowFlag!: boolean // 是否显示更多功能下拉按钮（true显示）
  @Prop() public moreShowProps!: any // 更多功能下拉按钮下拉菜单内容
  public moreShowPropsException: any = [{ menus: '', lables: '' }] // 初始化下拉菜单;存在时，默认有一条数据
  public LineShowFlag: boolean = true // 切换tag/List显示
  public searchValue: string = '' // 搜索的value
  public plusFlag: boolean = false // Add button ON 是否点击plus按钮
  public loading: boolean = true
  public lineShowFlagChange() {
    this.LineShowFlag = !this.LineShowFlag
    this.$emit('searchTool', {
      searchValue: this.searchValue,
      lineShowFlag: this.LineShowFlag,
      searchType: this.searchType,
      plusFlag: false,
    })
  }
  @Watch('searchValue') public DataSearch(e) {
    this.searchValue = e
    this.$emit('searchTool', {
      searchValue: this.searchValue,
      lineShowFlag: this.LineShowFlag,
      searchType: this.searchType,
      plusFlag: false,
    })
  }
  @Watch('$store.state.main.pagePublic.ranterId') public ranterChang() {
     this.searchValue = ''
  }
  public plusButtonTap() {
    this.plusFlag = true
    this.searchValue = ''
    this.$emit('searchTool', {
      searchValue: this.searchValue,
      lineShowFlag: this.LineShowFlag,
      searchType: this.searchType,
      plusFlag: this.plusFlag,
    })
  }
  public optionButtonTap() {
    this.searchValue = ''
    this.$emit('searchTool', {
      searchValue: this.searchValue,
      lineShowFlag: this.LineShowFlag,
      searchType: this.searchType,
      plusFlag: false,
      addNew: true,
    })
  }
  public moreDropTap(values) {
    this.searchValue = ''
    // 返回点击了droplist的哪一项
    this.$emit('searchTool', {
      searchValue: this.searchValue,
      lineShowFlag: this.LineShowFlag,
      searchType: this.searchType,
      plusFlag: false,
      dropTap: true,
      dropList: values,
    })
  }
  private created() {
    setTimeout(() => {
      this.loading = false
    }, 300)
  }
}
