import { requestToken, formDataTrans } from '../../../api/manage'
import { store } from '@/store'
import _ from 'lodash'
import { getStorageUserType, getGlobalUserType } from '@/utils/util'
export async function getAvatarUserData(userType: number, orgId: any) {
  let response: any
  response = await requestToken({
    url: `/api/v1/persons/${getGlobalUserType(userType)}/getPersonsOfBranchOrgs/${orgId}`,
    method: 'get',
  })
  const userData = response.map(
    (obj, index) => {
      obj.key = obj.id // 添加key属性  add property 'key'
      obj.rowKey = index
      switch (obj.gender) {
        case 0:
          obj.gender = window['vm'].$i18n.tc('user.authority.sex_o')
          break
        case 1:
          obj.gender = window['vm'].$i18n.tc('user.authority.sex_m')
          break
        case 2:
          obj.gender = window['vm'].$i18n.tc('user.authority.sex_f')
          break
      }
      return obj
  })

  store.commit('setUserData', userData)
  return response
}
const orgNameShowAdjust = (array : any) => {
    if (array) {
      const nameArry = array.map((orgIDs) => {
        return orgIDs.name
      })
      if (array.length > 1) {
        return _.join(nameArry, ',')
      } else {
        return nameArry
      }
    }
    return ''
}
// 查询组织下的人员包含自组织人员
export async function getPersonBranchORG(userType: number, orgId: any) {
  let response: any
  response = await requestToken({
    url: `/api/v1/persons/${getGlobalUserType(userType)}/getPersonsOfBranchOrgs/${orgId}`,
    method: 'get',
  })
  const userData = response.map(
    (obj, index) => {
      obj.orgs_name = orgNameShowAdjust(obj.org_ids)
      obj.key = obj.id // 添加key属性  add property 'key'
      obj.age = obj.age ? obj.age.toString() : '0'
      // if (_.indexOf( ['111', '113' , '114' , '131' , '335' , '414' , '782' , '990'] ,
      //  obj.certificate_type) === -1) {
      //   obj.certificate_type = ''
      // }
      obj.rowKey = index
      switch (obj.gender) {
        case 0:
          obj.gender = '0'
          break
        case 1:
          obj.gender = '1'
          break
        case 2:
          obj.gender = '2'
          break
      }
      return obj
  })

  store.commit('setUserData', userData)
  return response
}

export async function getOnePersonDetail(personId: any) {
  const response: any = await requestToken({
        url: `/api/v1/persons/${getStorageUserType()}/getPersonWithFaces/${personId}`,
        method: 'get',
      })
  store.state.main.userAuthorityShow.userData = response
}
export async function updateUserInfo(persondata: any, orgID : any) {
  return requestToken({
    url: `/api/v1/persons/multiUpdatePerson/${orgID}`,
    method: 'put',
    data: persondata,
  })
}
export async function personInfoCover(data: any, orgId: any) {
  // 覆盖更新person(csv用)
  return requestToken({
    url: '/api/v1/persons/coverAllPersons/' + orgId,
    method: 'put',
    data,
  })
}
// 取得用户绑定的设备（根据后台分类属于person的处理） get device Data that person binding with 　ユーザービンゴデバイスのデータを取得
export async function getDeviceData_BindPerson(userType: number, personID: number) {
  let response: any = null
  response = await requestToken({
    url: `/api/v1/persons/${getGlobalUserType(userType)}/getPersonWithDevices/${personID}`,
    method: 'get',
  })
  store.commit('setUserData', response)
}

// 多重绑定用户与设备（根据后台分类属于person的处理）
export function multiBindPersonDevices(person: any, deviceIDs: any, orgIds: any) {
  requestToken({
    url: `/api/v1/persons/multiBindPersonDevices/${orgIds}/${person.id}`,
    method: 'put',
    data: { device_ids: deviceIDs },
  })
}
export async function personFileUpload(uploadFile: any, uploadSeq: any, orgId: any) {
  const formData = formDataTrans({ upload_file : uploadFile, upload_seq : uploadSeq, org_id : orgId })
  const response: any = await requestToken({
    headers: {
      'Content-Type': 'multipart/form-data;',
    },
    url: `/api/v1/persons/uploadFile`,
    method: 'post',
    data: formData,
  })
  store.state.main.messagePro = response
}

export async function addDataOfExcelPerson(orgId: any, filePath: any) {
  const response: any = await requestToken({
    url: `/api/v1/persons/addDataOfExcel/${orgId}`,
    method: 'post',
    data: {
      file: filePath
    },
    timeout: 600000
  })
  store.state.main.messagePro = response
}
// upload CSV file
// userType:平台管理员0 admin 、机构管理员1 ORG、机构操作员2 Operater
export async function csvFileUpload(uploadFile: any, uploadSeq: any, orgId: any) {
  const formData = formDataTrans({ upload_file : uploadFile, upload_seq : uploadSeq, org_id : orgId })
  const response: any = await requestToken({
    headers: {
      'Content-Type': 'multipart/form-data;',
    },
    url: `/api/v1/persons/uploadCsv`,
    method: 'post',
    data: formData,
  })
  store.state.main.messagePro = response
}
export function addCSVFile(userType: number, csvFile: any) {
  requestToken({
    url: `/api/v1/persons/${getGlobalUserType(userType)}/addDataOfCsv`,
    method: 'post',
    data: csvFile,
  })
}

// Multi delete person Info
// userType:平台管理员0 admin 、机构管理员1 ORG、机构操作员2 Operater
export function deleteAll(userType: number, rowIDSelect: BigInteger[]) {
  rowIDSelect = _.compact(rowIDSelect)
  return requestToken({
    url: `/api/v1/persons/${getGlobalUserType(userType)}`,
    method: 'delete',
    data: rowIDSelect,
  })
}
// 逻辑删除人员
export function logicalDeleteAll(orgId: any, rowIDSelect: BigInteger[]) {
  rowIDSelect = _.compact(rowIDSelect)
  return requestToken({
    // url: `/api/v1/persons/${getGlobalUserType(userType)}`,
    url:  `/api/v1/persons/logicalDeletePersonsOfOrg/${orgId}`,
    method: 'delete',
    data: rowIDSelect,
  })
}
// Single delete person Info
// userType:平台管理员0 admin 、机构管理员1 ORG、机构操作员2 Operater
export function deleteOne(userType: number, rowIDSelect: number) {
  const personId = rowIDSelect
  requestToken({
    url: `/api/v1/persons/${getGlobalUserType(userType)}/${personId}`,
    method: 'delete',
  })
}
export function tableAddPersonInfo(userType: number, data: any) {
  requestToken({
    url: `/api/v1/persons/${getGlobalUserType(userType)}`,
    method: 'post',
    data,
  })
}
export async function creatNewPerson(userType: number, datas: any) {
  // 新建person
  let response: any
  response = await requestToken({
    url: `/api/v1/persons/${getGlobalUserType(userType)}`,
    method: 'post',
    data: datas,
  })
  store.state.main.messagePro = response
  return response
}
export async function personAvatar(image: any, uploadseq: any, ranterId: any) {
  // 添加person的头像传入图片后返回url[]与seq（顺序）
  let response: any
  const formData = formDataTrans({ upload_file: image, upload_seq: uploadseq, org_id: ranterId })
  response = await requestToken({
    url: `/api/v1/persons/uploadImage`,
    method: 'post',
    data: formData,
  })
  store.state.main.messagePro = response
}
export async function getImageByPerson(orgId: any, filePath: any) {
  const response: any = await requestToken({
    headers: {
      Accept: 'application/json',
    },
    url: '/api/v1/persons/getFile/' + orgId + '/' + filePath,
    method: 'get',
    responseType: 'blob'
  })
  store.state.main.messagePro = response
}
export  function deletePersonFace(personFaceId: any) {
   requestToken({
    url: `/api/v1/persons/${getStorageUserType()}/personFace/${personFaceId}`,
    method: 'delete',
  })
}
export  function multDeletePersonFace(personFaceIdArray: any, personID: any) {
  requestToken({
   url: `/api/v1/persons/${getStorageUserType()}/multiDeletePersonFace/${personID}`,
   method: 'delete',
   data: {person_face_ids : personFaceIdArray},
 })
}
export function personInfoUpdate(orgId: any, data: any) {
  // 更新person
  return requestToken({
    url: `/api/v1/persons/updatePerson/` + orgId,
    method: 'put',
    data,
  })
}

export function addPersonFaces(userType: number, personId: any, data: any) {
  // 添加person的头像
  requestToken({
    url: `/api/v1/persons/${getGlobalUserType(userType)}/addPersonFaces/${personId}`,
    method: 'post',
    data,
  })
}
// 下载人员的csv文件模版
export async function downloadTempCsv() {
  const response : any = requestToken({
    headers: {
      Accept: 'application/json',
    },
    url: `/api/v1/persons/downloadCsvTemplate`,
    method: 'get',
    responseType: 'blob'
  })
  store.state.main.messagePro = response
}

// 下载人员的csv数据文件
export async function downloadDataCsv(transParams : any , orgId : any) {
  const response : any = requestToken({
    headers: {
      Accept: 'application/json',
    },
    url: `/api/v1/persons/${getStorageUserType()}/exportCsv/${orgId}`,
    method: 'get',
    params: transParams,
    responseType: 'blob',
  })
  store.state.main.messagePro = response
}

export async function downloadDataZip(transParams : any , orgId : any) {
  const response : any = requestToken({
    headers: {
      Accept: 'application/json',
    },
    url: `/api/v1/persons/exportFile/${orgId}`,
    method: 'get',
    params: transParams,
    responseType: 'blob',
  })
  store.state.main.messagePro = response
}
/**
 *
 * 高级权限控制user
 *
 */

/**
 * 根据用户类型 获取全部user信息
 * @param {string} userType 用户类型
 * @returns {AxiosResponse}
 */
export function getUsers() {
  return requestToken({
    url: `/api/v1/users/${getStorageUserType()}`,
    method: 'get',
  })
}


// 详细user信息获取（管理员权限读取）
export function getUsersDetailBySpuer(userId) {
  return requestToken({
    url: `/api/v1/users/superuser/${userId}`,
    method: 'get',
  })
}

/*------------------ 新增 --------------------- */
export function addUser(user) {
  return requestToken({
    url: `/api/v1/users/${getStorageUserType()}/createUser`,
    data: user,
    method: 'post',
  })
}

/*------------------ 更新 --------------------- */
export function updateUser(userId, user) {
  return requestToken({
    url: `/api/v1/users/${getStorageUserType()}/${userId}`,
    data: user,
    method: 'put',
  })
}

export function updateUserOrg(userId, orgIds) {
  return requestToken({
    url: `/api/v1/users/${getStorageUserType()}/multiOwnUserOrgs/${userId}`,
    data: { org_ids: orgIds },
    method: 'put',
  })
}

/**
 * 根据UserId 获取 User
 * @param userId 用户Id
 * @returns
 */
export function getUserById(userId, ) {
  return requestToken({
    url: `/api/v1/users/${getStorageUserType()}/${userId}`,
    method: 'get',
  })
}

/*------------------ 删除 --------------------- */

/**
 * 删除一个用户
 * @param userId 要删除用户的ID
 * @returns
 */
export function deleteOneUser(userId: number) {
  return requestToken({
    url: `/api/v1/users/${getStorageUserType()}/${userId}`,
    method: 'delete',
  })
}

/**
 * 删除多个用户
 * @param userId 要删除用户的ID集合
 * @returns
 */
export function deleteMultiUser(userIds: number[]) {
  return requestToken({
    url: `/api/v1/users/${getStorageUserType()}/`,
    data: userIds,
    method: 'delete',
  })
}

/*------------------ 邀请用户 --------------------- */
export function inviteUser(orgId, emails) {
  return requestToken({
    url: `/api/v1/users/inviteOtherUsers/${orgId}`,
    data: emails,
    method: 'post',
  })
}

export function inviteUserLink(orgId, emails) {
  return requestToken({
    url: `/api/v1/users/get_invite_users_link/${orgId}`,
    data: emails,
    method: 'post',
  })
}
